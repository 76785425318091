import React, { useEffect, useState } from "react";
import "./banner.css";

// import VideoPlayer from "./videoplayer";
import videoSource from "../../Assets/hero.mp4";
import VideoAsGif from "./videoplayer";
import axios from "axios";
function Banner() {
  const endpoint = "https://backend.picklearcade.com/graphql";
  const headers = {
    "Content-Type": "application/json",
    // Include any other headers you need, such as Authorization
  };
  const [ArcadeData, setArcadeData] = useState({
    player: 0,
    matches: 0,
    adawagered: 0,
  });

  // Define the GraphQL query
  const query = `
 query {
    getAdminMainStats {
      body {
        users
        wager
        matchesInfo {
          totalMatches
        }
      }
    }
 }
`;

  // Configure the Axios request
  const response = axios({
    url: endpoint,
    method: "post",
    headers: headers,
    data: {
      query: query,
      // If your query has variables, include them here
      variables: {},
    },
  });
  useEffect(() => {
    response
      .then(({ data }) => {
        console.log(data.data);
        setArcadeData({
          player: data.data.getAdminMainStats.body.users.toLocaleString(),
          matches:
            data.data.getAdminMainStats.body.matchesInfo.totalMatches.toLocaleString(),
          adawagered: data.data.getAdminMainStats.body.wager.toLocaleString(),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Handle the response

  return (
    <div className="banner">
      <div className="banner-container">
        <div className="banner-left">
          <h2>Welcome to the new era of online arcades</h2>

          <p>
            Jump into the premier online platform where competitive gaming meets
            cryptocurrency wagering.
          </p>
          {/* <h4>Arcade Stats</h4> */}
          <div className="cards-container">
            <div className="banner-card">
              <h3>{ArcadeData.player}+</h3>
              <p>Players Registered</p>
            </div>
            <div className="banner-card">
              <h3>{ArcadeData.matches}+</h3>
              <p>Matches Played</p>
            </div>
            <div className="banner-card">
              <h3>{ArcadeData.adawagered}+</h3>
              <p>ADA Wagered</p>
            </div>
          </div>
        </div>
        {/* <div className="banner-right">
        <img src={pick} style={{width:"200px",margin:"0 auto"}}/>
        
        </div> */}
      </div>
    </div>
  );
}

export default Banner;
